/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {Permission} from "./Permission";
import {AuthorizationFunction} from "./AuthorizationFunction";
import {useUserAuthorizations} from "./context";
import {features} from "../features";

function toFlag(permission: Permission): string {
  // If the permission has a legacy feature flag, use that
  if (permission.legacyFeatureFlag) {
    return permission.legacyFeatureFlag;
  }
  // Otherwise, return the usual flag
  return `pdt.permission.${permission.resource}.${permission.action}`;
}

/**
 * <i><b>Note:</b> currently access control is being implemented
 * with LaunchDarkly; this hook is intended to insulate
 * callers from a change in the access control mechanism. It
 * is expected that in the future, perform an authorization
 * check against some service for the current {@link Subject}</i>
 */
export const useAuthorization = (): AuthorizationFunction => {
  const flags = useFlags();
  const {isUserAuthorized} = useUserAuthorizations();

  const authorizationFunction: AuthorizationFunction = flags[
    features.pdt.usePermissionsApi
  ]
    ? isUserAuthorized
    : permissions =>
        Promise.resolve(permissions.map(toFlag).every(flag => !!flags[flag]));

  return useCallback<AuthorizationFunction>(authorizationFunction, [
    flags,
    isUserAuthorized
  ]);
};
