import {OperationalHierarchyPermission} from "./OperationalHierarchyPermission";

export const EDIT_DBA_NAME: OperationalHierarchyPermission =
  new OperationalHierarchyPermission(
    "EditDBAName",
    "pdt.permission.operational-entity.edit"
  );

export const MANAGE_SIMPLE_OPERATIONAL_ENTITY: OperationalHierarchyPermission =
  new OperationalHierarchyPermission(
    "ManageSimpleOperationalEntity",
    "pdt.permission.operational-entity.create"
  );

export const MANAGE_COMPLEX_OPERATIONAL_ENTITY: OperationalHierarchyPermission =
  new OperationalHierarchyPermission(
    "ManageComplexOperationalEntity",
    "pdt.permission.operational-entity.create-complex-entities"
  );

export const EDIT_ENTERPRISE_ENTITLEMENTS: OperationalHierarchyPermission =
  new OperationalHierarchyPermission(
    "EditEnterpriseEntitlements",
    "pdt.permission.operational-entity.edit-composed-solutions"
  );

export const EDIT_STATUS: OperationalHierarchyPermission =
  new OperationalHierarchyPermission(
    "EditStatus",
    "pdt.permission.operational-entity.edit"
  );

export const EDIT_PARENT_ID: OperationalHierarchyPermission =
  new OperationalHierarchyPermission(
    "EditParentId",
    "pdt.permission.operational-entity.edit"
  );

export const MANAGE_BOID_ENTITIES: OperationalHierarchyPermission =
  new OperationalHierarchyPermission(
    "ManageBOIDEntities",
    "pdt.permission.operational-entity.edit"
  );

export const ASSIGN_BOID_ENTITY: OperationalHierarchyPermission =
  new OperationalHierarchyPermission(
    "AssignBOIDEntity",
    "pdt.permission.operational-entity.edit"
  );

export const UNASSIGN_BOID_ENTITY: OperationalHierarchyPermission =
  new OperationalHierarchyPermission(
    "UnassignBOIDEntity",
    "pdt.permission.operational-entity.edit"
  );

export const operationalHierarchyPermissions: OperationalHierarchyPermission[] =
  [
    EDIT_DBA_NAME,
    MANAGE_SIMPLE_OPERATIONAL_ENTITY,
    MANAGE_COMPLEX_OPERATIONAL_ENTITY,
    EDIT_ENTERPRISE_ENTITLEMENTS,
    EDIT_STATUS,
    EDIT_PARENT_ID,
    MANAGE_BOID_ENTITIES,
    ASSIGN_BOID_ENTITY,
    UNASSIGN_BOID_ENTITY
  ];
