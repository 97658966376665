import {
  TableBody,
  TableCell,
  TableRow
} from "@interstate/components/SimpleTable";
import {FC} from "react";
import {AddressProps} from "../operational-hierarchy/overview-tab/OperationalAddress";
import {useTranslation} from "react-i18next";
import "./AddressTableBody.scss";
export const AddressTableBody: FC<AddressProps> = ({
  id,
  streetAddressLine1,
  streetAddressLine2,
  city,
  state,
  zip,
  country,
  telecomNumbers
}) => {
  const {t} = useTranslation();
  return (
    <TableBody
      id={`address-table-body-${id}`}
      data-testid={`address-table-body-${id}`}
      className={"address-table-body address-body"}>
      <TableRow className={"address-row"}>
        <TableCell className={"address-field"}>
          {t("entity-detail.dealer.address.street")}
        </TableCell>
        <TableCell className={"address-value"}>{streetAddressLine1}</TableCell>
      </TableRow>
      <TableRow className={"address-row"}>
        <TableCell className={"address-field"}>
          {t("entity-detail.dealer.address.streetAddressLine2")}
        </TableCell>
        <TableCell className={"address-value"}>{streetAddressLine2}</TableCell>
      </TableRow>
      <TableRow className={"address-row"}>
        <TableCell className={"address-field"}>
          {t("entity-detail.dealer.address.city")}
        </TableCell>
        <TableCell className={"address-value"}>{city}</TableCell>
      </TableRow>
      <TableRow className={"address-row"}>
        <TableCell className={"address-field"}>
          {t("entity-detail.dealer.address.state")}
        </TableCell>
        <TableCell className={"address-value"}>{state}</TableCell>
      </TableRow>
      <TableRow className={"address-row"}>
        <TableCell className={"address-field"}>
          {t("entity-detail.dealer.address.zip-code")}
        </TableCell>
        <TableCell className={"address-value"}>{zip}</TableCell>
      </TableRow>
      <TableRow className={"address-row"}>
        <TableCell className={"address-field"}>
          {t("entity-detail.dealer.address.country")}
        </TableCell>
        <TableCell className={"address-value"}>{country}</TableCell>
      </TableRow>
      <TableRow className={"address-row"}>
        <TableCell className={"address-field"}>
          {t("entity-detail.dealer.phone")}
        </TableCell>
        <TableCell className={"address-value"} id={"address-phone-numbers"}>
          <ul className={"phone-numbers"}>
            {telecomNumbers?.map((phone: string) => (
              <li key={phone} className={"phone-number"}>
                {phone}
              </li>
            ))}
          </ul>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
