import {
  type Oem,
  oemUniqueKey
} from "@common-core/coat-operational-hierarchy-appsync-model";

type PropertyAccessor = (row: OemTableRow) => string | undefined;

const accessors: PropertyAccessor[] = [
  (row: OemTableRow) => row.abbreviation,
  (row: OemTableRow) => row.id,
  (row: OemTableRow) => row.nameplateDescription,
  (row: OemTableRow) => row.locationId
];

const comparing = (
  a: OemTableRow,
  b: OemTableRow,
  accessor: PropertyAccessor
): number => {
  const aValue = accessor(a) || "";
  const bValue = accessor(b) || "";
  return aValue.localeCompare(bValue);
};

export interface OemTableRow extends Oem {
  key: string;
}

export const toOemTableRow = (oem: Oem): OemTableRow => ({
  ...oem,
  key: oemUniqueKey(oem)
});

export const comparingOemTableRows = (
  a: OemTableRow,
  b: OemTableRow
): number => {
  return (
    accessors
      .map(accessor => comparing(a, b, accessor))
      .find(result => result !== 0) || 0
  );
};
