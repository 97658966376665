import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {TabRow} from "../../../tab-content";
import "./OperationalAddress.scss";
import {AddressTableBody} from "../../overview/AddressTableBody";
import {
  OrganizationAddress,
  Source
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {useLazyQuery} from "@apollo/client";
import {
  LookUpClientEntitiesByIds,
  LookupClientEntitiesResponse
} from "../../backend";
import {Endpoints} from "../../../runtime";
import {FullSource, toFullSource} from "../../util/toNamedSources";
import {useToastErrorHandler} from "../../../backend";
import {PermissionBasedEditButton} from "../../../action-buttons";
import {OnCompleteCallback} from "../../../utils";
import {EditAddressModal} from "./EditAddressModal";
import {assertClientsPresent} from "../../boids/transforms";
import {SimpleTable} from "@interstate/components/SimpleTable";

export interface AddressProps extends OrganizationAddress {
  id: string;
  telecomNumbers?: string[];
  sources?: Source[];
}

export const OperationalAddress: FC<AddressProps> = ({
  id,
  streetAddressLine1,
  streetAddressLine2,
  city,
  state,
  zip,
  country,
  telecomNumbers,
  sources
}) => {
  const {t} = useTranslation();
  const [fullSources, setFullSources] = useState<FullSource[]>([]);
  const [editingAddress, setEditingAddress] = useState<boolean>(false);
  const toastError = useToastErrorHandler();

  const [lookUpClientEntities, {loading}] =
    useLazyQuery<LookupClientEntitiesResponse>(LookUpClientEntitiesByIds, {
      context: {
        endpoint: Endpoints.APPSYNC
      },
      fetchPolicy: "network-only"
    });

  const beginEditing = () => {
    if (sources && sources.length > 0) {
      const ids = sources.map(source => source.sourceId);
      lookUpClientEntities({variables: {ids}})
        .then(assertClientsPresent)
        .then(toFullSource)
        .then(fullSources => {
          setFullSources(fullSources);
        })
        .then(() => {
          setEditingAddress(true);
        })
        .catch(toastError);
    } else {
      setEditingAddress(true);
    }
  };

  const finishAddressEditing: OnCompleteCallback = () => {
    setEditingAddress(false);
  };

  return (
    <TabRow
      name={"operational-address"}
      header={
        <h4>
          {t("entity-detail.dealer.address.details")}
          <PermissionBasedEditButton
            onClick={beginEditing}
            loading={loading}
            qualifier={"address"}
            tooltip={t("entity-detail.dealer.address.edit")}
            inline={false}
            permissions={[
              {
                resource: "operational-entity",
                action: "edit"
              }
            ]}
          />
        </h4>
      }>
      {editingAddress && (
        <EditAddressModal
          id={id}
          sources={!sources || fullSources.length === 0 ? [] : fullSources}
          currentAddress={
            {
              streetAddressLine1,
              streetAddressLine2,
              city,
              state,
              zip,
              country
            } as OrganizationAddress
          }
          onComplete={finishAddressEditing}
        />
      )}
      <SimpleTable
        id={`operational-address-table-${id}`}
        data-testid={`operational-address-table-${id}`}
        dataDensity={"small"}
        background={"white"}
        className={"operational-address-table"}
        bordered={false}
        hover={false}
        striped={false}>
        <AddressTableBody
          id={id}
          streetAddressLine1={streetAddressLine1}
          streetAddressLine2={streetAddressLine2}
          city={city}
          state={state}
          country={country}
          zip={zip}
          telecomNumbers={telecomNumbers ? telecomNumbers : []}
        />
      </SimpleTable>
    </TabRow>
  );
};
