import {Permission} from "./Permission";

/**
 * The {@link OperationalHierarchyPermission} captures a pre-defined
 * set of actions that can be performed on an OperationalHierarchy.
 *
 * It is important to note that an OperationalHierarchy differs from
 * typical protected objects in that it is purely a conceptual/logical
 * construct.
 *
 * Conceptually, there is only one instance of an OperationalHierarchy
 * in our system; it has been assigned a fabricated identifier of coxauto.
 */
export class OperationalHierarchyPermission implements Permission {
  readonly resource = "OperationalHierarchy";
  // This is a fabricated identifier conceptually representing THE single instance of an OperationalHierarchy
  readonly instance = "coxauto";
  readonly action: string;
  readonly legacyFeatureFlag?: string;

  constructor(action: string, legacyFeatureFlag?: string) {
    this.action = action;
    this.legacyFeatureFlag = legacyFeatureFlag;
  }
}
