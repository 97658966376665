import {FC} from "react";
import {Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {LoginCallback, SecureRoute} from "@okta/okta-react";
import Logout from "@common-core/okta-logout";
import {useSidebarItems} from "./hooks";
import {ActivityIndicator} from "./activity-indicator";
import {
  AuthorizationCheck,
  Authorized,
  MANAGE_SIMPLE_OPERATIONAL_ENTITY,
  Unauthorized
} from "./access";
import {ErrorPage} from "./layout";
import {ProfilePage} from "./profile";

import {OperationalEntityPage} from "./entities/operational-hierarchy";
import {CreateOperationalEntityPage} from "./entities/operational-hierarchy/create";
import {ClientHierarchyPage} from "./entities/client-hierarchy";
import {ClientHierarchySearchPage} from "./entities/client-hierarchy/search";
import {OperationalSearchPage} from "./entities/operational-hierarchy/search";
import {OperationalHomePage} from "./home/operational-hierarchy";

/**
 * The {@link Routes} component is responsible for routing
 * requests within the application to the appropriate component.
 *
 * @constructor
 */
export const Routes: FC<{}> = () => {
  const {t} = useTranslation();
  const sidebarItems = useSidebarItems();

  return (
    <Switch>
      <Route path="/login/callback">
        <LoginCallback
          loadingElement={<ActivityIndicator />}
          errorComponent={ErrorPage}
        />
      </Route>
      <Route path="/logout/callback">
        <Logout />
      </Route>
      <SecureRoute path="/" exact={true} errorComponent={ErrorPage}>
        <OperationalHomePage />
      </SecureRoute>
      <SecureRoute path="/search" errorComponent={ErrorPage}>
        <OperationalSearchPage />
      </SecureRoute>
      <SecureRoute path="/entity/:id" errorComponent={ErrorPage}>
        <OperationalEntityPage />
      </SecureRoute>
      <SecureRoute path="/client-hierarchy-search" errorComponent={ErrorPage}>
        <ClientHierarchySearchPage />
      </SecureRoute>
      <SecureRoute path="/client-hierarchy/:id" errorComponent={ErrorPage}>
        <ClientHierarchyPage />
      </SecureRoute>
      <SecureRoute
        path="/create-operational-entity/:id"
        errorComponent={ErrorPage}>
        <AuthorizationCheck permissions={[MANAGE_SIMPLE_OPERATIONAL_ENTITY]}>
          <Authorized>
            <CreateOperationalEntityPage />
          </Authorized>
          <Unauthorized>
            <ErrorPage
              error={t("error-page.not-authorized", "???")}
              severity={"caution"}
              sidebarItems={sidebarItems}
              logo={false}
            />
          </Unauthorized>
        </AuthorizationCheck>
      </SecureRoute>
      <SecureRoute path="/profile" errorComponent={ErrorPage}>
        <ProfilePage />
      </SecureRoute>
      <Route path="/error-details">
        <ErrorPage
          error={window.error}
          severity={"error"}
          sidebarItems={sidebarItems}
          logo={false}
        />
        )
      </Route>
      <Route>
        <ErrorPage
          error={t("error-page.invalid-route", "???")}
          severity={"error"}
          sidebarItems={sidebarItems}
          logo={false}
        />
      </Route>
    </Switch>
  );
};
