import {TabRow} from "../../../tab-content";
import {CheckCircleIcon} from "@interstate/components/Icons";
import "./EnterpriseEntitlements.scss";
import {useOperationalEntity} from "../../context";
import {EditEnterpriseEntitlementsModal} from "./EditEnterpriseEntitlementsModal";
import {PermissionBasedEditButton} from "../../../action-buttons";
import {useState} from "react";
import {OnCompleteCallback} from "../../../utils";
import {useTranslation} from "react-i18next";
import {Typography} from "@interstate/components/Typography";
import {EDIT_ENTERPRISE_ENTITLEMENTS} from "../../../access";
export const EnterpriseEntitlements = () => {
  const {t} = useTranslation();

  const {
    entity: {id, enterpriseEntitlements}
  } = useOperationalEntity();

  const enabledEnterpriseEntitlements =
    enterpriseEntitlements?.filter(
      enterpriseEntitlement => enterpriseEntitlement.enabled
    ) ?? [];

  const [editingEnterpriseEntitlements, setEditingEnterpriseEntitlements] =
    useState<boolean>(false);

  const finishEnterpriseEntitlementEditing: OnCompleteCallback = () => {
    setEditingEnterpriseEntitlements(false);
  };

  return (
    <TabRow
      name={"enterprise-entitlements"}
      header={
        <header
          id={`enterprise-entitlements-header-${id}`}
          data-testid={`enterprise-entitlements-header-${id}`}
          className={"details-column-header"}>
          {editingEnterpriseEntitlements && (
            <EditEnterpriseEntitlementsModal
              id={id}
              onComplete={finishEnterpriseEntitlementEditing}
              initialEnterpriseEntitlements={enterpriseEntitlements || []}
            />
          )}
          <hgroup className={"details-column-header-group"}>
            <h4>{t("entity-detail.dealer.enterprise-entitlements.details")}</h4>
            <PermissionBasedEditButton
              onClick={() => setEditingEnterpriseEntitlements(true)}
              qualifier={"enterprise-entitlements"}
              tooltip={t("entity-detail.dealer.enterprise-entitlements.edit")}
              permissions={[EDIT_ENTERPRISE_ENTITLEMENTS]}
            />
          </hgroup>
        </header>
      }>
      {enabledEnterpriseEntitlements.length > 0 ? (
        enabledEnterpriseEntitlements.map(enterpriseEntitlement => (
          <div
            key={enterpriseEntitlement.enterpriseEntitlementId}
            id={`enterprise-entitlement-${enterpriseEntitlement.enterpriseEntitlementId}`}
            data-testid={`enterprise-entitlement-${enterpriseEntitlement.enterpriseEntitlementId}`}
            className={"enterprise-entitlement-item"}>
            <CheckCircleIcon className={"enterprise-entitlement-item-icon"} />
            {enterpriseEntitlement.enterpriseEntitlementName}
          </div>
        ))
      ) : (
        <Typography
          tag={"span"}
          className={"no-enterprise-entitlements"}
          variant={"body-sm"}>
          {t("entity-detail.dealer.enterprise-entitlements.none")}
        </Typography>
      )}
    </TabRow>
  );
};
