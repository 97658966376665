import {FC} from "react";
import {useTranslation} from "react-i18next";
import {SimpleTable} from "@interstate/components/SimpleTable";
import {TabRow} from "../../../tab-content";
import "./ClientAddress.scss";
import {OrganizationAddress} from "@common-core/coat-operational-hierarchy-appsync-model";
import {AddressTableBody} from "../../overview/AddressTableBody";

interface AddressProps extends OrganizationAddress {
  id: string;
  telecomNumbers?: string[];
}

export const ClientAddress: FC<AddressProps> = ({
  id,
  streetAddressLine1,
  streetAddressLine2,
  city,
  state,
  zip,
  country,
  telecomNumbers
}) => {
  const {t} = useTranslation();
  return (
    <TabRow
      name={"client-address"}
      header={<h4>{t("entity-detail.dealer.address.details")}</h4>}>
      <SimpleTable
        id={`client-address-table-${id}`}
        data-testid={`client-address-table-${id}`}
        dataDensity={"small"}
        background={"white"}
        className={"client-address-table"}
        bordered={false}
        hover={false}
        striped={false}>
        <AddressTableBody
          id={id}
          streetAddressLine1={streetAddressLine1}
          streetAddressLine2={streetAddressLine2}
          city={city}
          state={state}
          country={country}
          zip={zip}
          telecomNumbers={telecomNumbers ? telecomNumbers : []}
        />
      </SimpleTable>
    </TabRow>
  );
};
