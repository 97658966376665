import {FC, PropsWithChildren, useCallback, useEffect} from "react";
import {useLazyQuery} from "@apollo/client";
import {
  LookupUserAuthorizations,
  LookupUserAuthorizationsResponse
} from "../backend";
import {UserAuthorizationContext} from "./UserAuthorizationContext";
import {Endpoints} from "../../runtime";
import {operationalHierarchyPermissions} from "../OperationalHierarchyPermissions";
import {transformUserAuthorizations} from "./UserAuthorizationsMap";
import {Permission} from "../Permission";
import {OperationalHierarchyPermission} from "../OperationalHierarchyPermission";
import {useOktaAuth} from "@okta/okta-react";

// For now there is a mismatch between the permissions type in the UI and the authorization type on the backend
// To keep things clean we will transform the permissions to the backend type here
const operationalHierarchyPermissionsTransform = (
  operationalHierarchyPermissions: OperationalHierarchyPermission[]
) => {
  return operationalHierarchyPermissions.map(permission => ({
    resourceIdentifier: permission.instance,
    resourceType: permission.resource,
    action: permission.action
  }));
};

export interface UserAuthorizationProviderProps {
  waitForLoading?: boolean;
}

export const UserAuthorizationProvider: FC<
  PropsWithChildren<UserAuthorizationProviderProps>
> = ({waitForLoading = true, children}) => {
  // Since this component is a used before the Routes are loaded, we need to make sure that the user is authenticated
  // Otherwise the component would call the lookupUserAuthorizations query when the user is not authenticated and the query would fail
  const {authState} = useOktaAuth();
  const [lookupUserAuthorizations, {data, error}] =
    useLazyQuery<LookupUserAuthorizationsResponse>(LookupUserAuthorizations, {
      variables: {
        userAuthorizations: operationalHierarchyPermissionsTransform(
          operationalHierarchyPermissions
        )
      },
      context: {
        endpoint: Endpoints.APPSYNC
      }
    });

  useEffect(() => {
    if (authState?.isAuthenticated) {
      lookupUserAuthorizations();
    }
  }, [authState, lookupUserAuthorizations]);

  const isUserAuthorized = useCallback(
    (permissions: Permission[]) => {
      if (error || !data) {
        return Promise.resolve(false);
      }

      const userAuthorizationsMap = transformUserAuthorizations(
        data.userAuthorizations ?? []
      );

      return Promise.resolve(
        permissions.every(permission => {
          const key = `${permission.instance}.${permission.resource}.${permission.action}`;
          return userAuthorizationsMap[key];
        })
      );
    },
    [data, error]
  );

  // Returns the children when:
  // 1. When the user is authenticated and the query returned data or an error. This is the happy path case.
  // 2. When the user is not authenticated (therefore no data and no error since the query was not called)

  // Since this page is above the Routes, we don't want to break the rendering of the child routes (such as the login callback) when the user is not authenticated
  // But when the user is authenticated, we want to make sure that the query is called and completed before rendering the children

  return data || error || !authState?.isAuthenticated || !waitForLoading ? (
    <UserAuthorizationContext.Provider
      value={{
        isUserAuthorized
      }}>
      {children}
    </UserAuthorizationContext.Provider>
  ) : null;
};
