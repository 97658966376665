import {FC} from "react";
import {useTranslation} from "react-i18next";
import {TabRow} from "../../../tab-content";
import {OemTableBody} from "../../overview";
import {OemsProps} from "../overview-tab/OperationalOems";
import "./EditorOems.scss";

export const EditorOems: FC<OemsProps> = ({id, oems}) => {
  const {t} = useTranslation();
  return (
    <TabRow
      name={"editor-oems"}
      header={<h4>{t("entity-detail.dealer.oems.details")}</h4>}>
      <OemTableBody id={id} oems={oems} />
    </TabRow>
  );
};
