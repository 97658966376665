import {FC, ReactNode, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  BoidAssignment,
  OperationalEntity,
  UpdateResponse
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {Badge} from "@interstate/components/Badge";
import {MinusCircleIcon, PlusCircleIcon} from "@interstate/components/Icons";
import {useInterstateToken} from "@interstate/components/InterstateThemeProvider";
import {List, ListItem} from "@interstate/components/List";
import {useAlert, ModalWithAlert} from "../../alerting";
import {Endpoints} from "../../runtime";
import {
  AddBoidAssignment,
  EntityResponse,
  LookUpClientEntitiesByIds,
  LookupClientEntitiesResponse,
  LookupOperationalEntity,
  RemoveBoidAssignment
} from "../backend";
import {useOperationalEntity} from "../context";
import {BoidGroup, NamedBoidGroup, byName} from "./model";
import {OnCompleteCallback} from "../../utils";
import {
  assertClientsPresent,
  assertEntityPresent,
  collectBoidAllocationsFromClientEntities,
  mergeBoidAssignmentsIntoBoidAllocations
} from "./transforms";
import "./EditBoidAssignmentsModal.scss";
import {
  ASSIGN_BOID_ENTITY,
  UNASSIGN_BOID_ENTITY,
  useAuthorization
} from "../../access";

type BoidAssignmentCallback<E = any> = (
  assignment: BoidAssignment,
  event: E
) => void;

export interface EditBoidAssignmentsModalProps {
  allocations: BoidGroup<Record<string, boolean>>[];
  onComplete: OnCompleteCallback;
}

export type BoidAssignmentStatus = "assigned" | "unassigned";

export const EditBoidAssignmentsModal: FC<EditBoidAssignmentsModalProps> = ({
  allocations,
  onComplete
}) => {
  const {t} = useTranslation();
  const token = useInterstateToken();
  const [working, setWorking] = useState<boolean>(false);
  const {entity, setEntity} = useOperationalEntity();
  const [currentAllocations, setCurrentAllocations] =
    useState<BoidGroup<Record<string, boolean>>[]>(allocations);
  const {alert, clearAlert, createErrorAlert} = useAlert({
    errorTitleKey: "toast.backend-error"
  });

  const [canAssignBoid, setCanAssignBoid] = useState<boolean>(false);
  const [canUnassignBoid, setCanUnassignBoid] = useState<boolean>(false);
  const granted = useAuthorization();

  useEffect(() => {
    granted([ASSIGN_BOID_ENTITY]).then(setCanAssignBoid);
    granted([UNASSIGN_BOID_ENTITY]).then(setCanUnassignBoid);
  }, [granted]);

  const [removeBoidAssignment] = useMutation<UpdateResponse>(
    RemoveBoidAssignment,
    {
      context: {
        endpoint: Endpoints.APPSYNC
      },
      fetchPolicy: "network-only"
    }
  );
  const [addBoidAssignment] = useMutation<UpdateResponse>(AddBoidAssignment, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });
  const [lookUpClientEntities] = useLazyQuery<LookupClientEntitiesResponse>(
    LookUpClientEntitiesByIds,
    {
      context: {
        endpoint: Endpoints.APPSYNC
      },
      fetchPolicy: "network-only"
    }
  );
  const [lookUpOperationalEntity] = useLazyQuery<
    EntityResponse<OperationalEntity>
  >(LookupOperationalEntity, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });

  const assignBoid: BoidAssignmentCallback = async (
    assignment
  ): Promise<void> => {
    setWorking(true);
    clearAlert();
    await addBoidAssignment({variables: {assignment}})
      .then(refreshEntity)
      .then(() => {
        window.pendo?.track("BOID Assigned", {
          ...assignment
        });
      })
      .catch(createErrorAlert)
      .finally(() => setWorking(false));
  };

  const unassignBoid: BoidAssignmentCallback = async (
    assignment
  ): Promise<void> => {
    setWorking(true);
    clearAlert();
    await removeBoidAssignment({variables: {assignment}})
      .then(refreshEntity)
      .then(() => {
        window.pendo?.track("BOID Unassigned", {
          ...assignment
        });
      })
      .catch(createErrorAlert)
      .finally(() => setWorking(false));
  };

  const updateEntityInContext = (
    entity: OperationalEntity
  ): OperationalEntity => {
    setEntity(entity);
    return entity;
  };

  const collectBoidAllocations = async (
    entity: OperationalEntity
  ): Promise<void> => {
    // Gather the client identifiers from which the current entity is sourced
    const ids = entity.sources.map(source => source.sourceId);
    // Collect the boid allocations grouped by type
    return await lookUpClientEntities({variables: {ids}})
      .then(assertClientsPresent)
      .then(collectBoidAllocationsFromClientEntities)
      .then(mergeBoidAssignmentsIntoBoidAllocations(entity.boidAssignments))
      .then(setCurrentAllocations);
  };

  const refreshEntity = async (): Promise<void> => {
    return await lookUpOperationalEntity({variables: {id: entity.id}})
      .then(assertEntityPresent)
      .then(updateEntityInContext)
      .then(collectBoidAllocations);
  };

  const editingComplete = () => {
    onComplete(!Object.is(allocations, currentAllocations));
  };

  const toNamedBoidGroup = (
    group: BoidGroup<Record<string, boolean>>
  ): NamedBoidGroup<Record<string, boolean>> => {
    return {
      name: t(`entity-detail.business-operation-types.${group.type}`),
      group
    };
  };

  const createBoidElement = (
    type: string,
    boid: string,
    status: BoidAssignmentStatus,
    actionPermitted: boolean,
    boidAssignmentCallback: BoidAssignmentCallback,
    endIcon: ReactNode
  ) => {
    return (
      <li
        key={`${type}-${boid}`}
        id={`${type}-${boid}-boid-badge-item`}
        data-testid={`${type}-${boid}-boid-badge-item`}
        className={`boid-badge-item boid-badge-item--${status}`}
        onClick={event => {
          if (actionPermitted) {
            const assignment: BoidAssignment = {
              businessOperationType: type,
              businessOperationId: boid,
              commonOrgId: entity.id
            };
            boidAssignmentCallback(assignment, event);
          }
        }}>
        <Badge
          id={`${type}-${boid}-boid-badge`}
          data-testid={`${type}-${boid}-boid-badge`}
          className={`boid-badge boid-badge--${status} ${actionPermitted ? "clickable" : ""}`}
          variant={status === "assigned" ? "success" : "light"}
          endIcon={actionPermitted ? endIcon : null}>
          {boid}
        </Badge>
      </li>
    );
  };

  const createAssignedBoidElement = (type: string, boid: string) => {
    return createBoidElement(
      type,
      boid,
      "assigned",
      canUnassignBoid,
      unassignBoid,
      <MinusCircleIcon data-testid={`${type}-${boid}-minus-icon`} />
    );
  };

  const createUnassignedBoidElement = (type: string, boid: string) => {
    return createBoidElement(
      type,
      boid,
      "unassigned",
      canAssignBoid,
      assignBoid,
      <PlusCircleIcon
        color={token("sem.color.border.success")}
        data-testid={`${type}-${boid}-plus-icon`}
      />
    );
  };

  return (
    <ModalWithAlert
      id={"edit-boid-assignments-modal"}
      data-testid={"edit-boid-assignments-modal"}
      show={true}
      size={"large"}
      header={`${t("entity-detail.boid-assignments")}`}
      alert={alert}
      footer={{
        primary: {
          action: editingComplete,
          label: t("common-actions.finish-editing"),
          isLoading: working
        }
      }}
      onHide={editingComplete}>
      <List
        id={"boid-allocations-list"}
        data-testid={"boid-allocations-list"}
        className={"boid-allocations-list"}
        sx={working ? {pointerEvents: "none"} : undefined}>
        {currentAllocations
          .map(toNamedBoidGroup)
          .sort(byName)
          .map(({name, group: {type, boids}}) => (
            <ListItem
              key={type}
              id={`${type}-boid-allocations-list-item`}
              data-testid={`${type}-boid-allocations-list-item`}
              className={"boid-allocations-list-item"}>
              <h4 className={"business-operation-type-name"}>{name}</h4>
              {Object.keys(boids).length > 0 ? (
                <ul
                  id={`${type}-boid-badge-list`}
                  data-testid={`${type}-boid-badge-list`}
                  className={"boid-badge-list"}>
                  {Object.entries(boids)
                    .sort(([boidA], [boidB]) => boidA.localeCompare(boidB))
                    .map(([boid, assigned]) => {
                      return assigned
                        ? createAssignedBoidElement(type, boid)
                        : createUnassignedBoidElement(type, boid);
                    })}
                </ul>
              ) : (
                <span
                  className={"no-assignments"}
                  data-testid={`no-${type}-assignments`}>
                  {t("entity-detail.business-operation-types.no-assignments")}
                </span>
              )}
            </ListItem>
          ))}
      </List>
    </ModalWithAlert>
  );
};
