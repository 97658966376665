export const features = {
  ops: {
    runtimeInspectorVisible: "ops.runtime-control.inspector",
    apolloClientConnectToDevTools: "ops.apollo-client.connect-to-devtools"
  },
  pdt: {
    permission: {
      viewClientEntity: "pdt.permission.client-entity.view",
      createOperationalEntity: "pdt.permission.operational-entity.create",
      editOperationalEntity: "pdt.permission.operational-entity.edit",
      createComplexEntities:
        "pdt.permission.operational-entity.create-complex-entities",
      editComposedSolutions:
        "pdt.permission.operational-entity.edit-composed-solutions"
    },
    pendo: {
      scriptInjectionEnabled: "pdt.pendo-script-injection"
    },
    showComposedSolutions: "pdt.show-composed-solutions",
    usePermissionsApi: "pdt.use-permissions-api"
  },
  dev: {
    unitTestFlag: "dev.unit-test-flag",
    editOems: "dev.edit-oems",
    components: {
      operationalHistory: "dev.components.oh-history"
    }
  }
};
