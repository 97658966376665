import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  SimpleTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@interstate/components/SimpleTable";
import {TabRow} from "../../tab-content";
import "./Sources.scss";
import {Source} from "@common-core/coat-operational-hierarchy-appsync-model";
import {OnCompleteCallback} from "../../utils";
import {
  EditMultipleSourcesModal,
  EditSourcesModal,
  NamedSources
} from "../operational-hierarchy/overview-tab";
import {
  ActionButtons,
  CopyButton,
  PermissionBasedEditButton
} from "../../action-buttons";
import {Typography} from "@interstate/components/Typography";
import {useLazyQuery} from "@apollo/client";
import {
  LookUpClientEntitiesByIds,
  LookupClientEntitiesResponse
} from "../backend";
import {Endpoints} from "../../runtime";
import {assertClientsPresent} from "../boids/transforms";
import {useToastErrorHandler} from "../../backend";
import {toNamedSources} from "../util/toNamedSources";
import {EntitySourceType} from "../EntitySourceType";
import {useNavigation} from "../../hooks";
import {
  AuthorizationCheck,
  Authorized,
  MANAGE_COMPLEX_OPERATIONAL_ENTITY,
  MANAGE_SIMPLE_OPERATIONAL_ENTITY,
  Unauthorized
} from "../../access";

export interface SourceProps {
  id: string;
  sources: Source[] | undefined;
  isComplex?: boolean;
}

export const Sources: FC<SourceProps> = ({id, sources, isComplex}) => {
  const {t} = useTranslation();
  const [namedSources, setNamedSources] = useState<NamedSources[]>([]);
  const [editingSources, setEditingSources] = useState<boolean>(false);
  const toastError = useToastErrorHandler();
  const {navigateToEntity} = useNavigation();
  const finishSourcesEditing: OnCompleteCallback = () => {
    setNamedSources([]);
    setEditingSources(false);
  };
  const [lookUpClientEntities, {loading}] =
    useLazyQuery<LookupClientEntitiesResponse>(LookUpClientEntitiesByIds, {
      context: {
        endpoint: Endpoints.APPSYNC
      },
      fetchPolicy: "network-only"
    });
  const beginEditing = () => {
    if (sources && sources.length > 0) {
      const ids = sources.map(source => source.sourceId);
      lookUpClientEntities({variables: {ids}})
        .then(assertClientsPresent)
        .then(toNamedSources)
        .then(namedSources => {
          // Handles the case where an entity has sources attached that are NOT in client hierarchy for some reason (deleted, manually added, etc)
          // In that case we need to add them here so that they can be removed if needed, otherwise they would not show up in the modal list
          sources.forEach(source => {
            const found = namedSources.find(
              namedSource => namedSource.sourceId === source.sourceId
            );
            if (!found) {
              namedSources.push({
                sourceId: source.sourceId,
                name: source.sourceId,
                sourceType: source.sourceType
              });
            }
          });
          return namedSources;
        })
        .then(setNamedSources)
        .then(() => setEditingSources(true))
        .catch(toastError);
    } else {
      setEditingSources(true);
    }
  };
  return (
    <TabRow
      name={"sources"}
      header={
        <h4>
          {t("entity-detail.dealer.sources.details")}
          <PermissionBasedEditButton
            onClick={beginEditing}
            loading={loading}
            qualifier={"source"}
            tooltip={t("entity-detail.dealer.sources.edit-all")}
            inline={false}
            permissions={[
              isComplex
                ? MANAGE_COMPLEX_OPERATIONAL_ENTITY
                : MANAGE_SIMPLE_OPERATIONAL_ENTITY
            ]}
          />
        </h4>
      }>
      {editingSources && (
        <AuthorizationCheck permissions={[MANAGE_COMPLEX_OPERATIONAL_ENTITY]}>
          <Authorized>
            <EditMultipleSourcesModal
              id={id}
              initalSources={
                !sources || namedSources.length === 0 ? [] : namedSources
              }
              onComplete={finishSourcesEditing}
            />
          </Authorized>
          <Unauthorized>
            <EditSourcesModal
              id={id}
              initalSources={!sources || sources.length === 0 ? [] : sources}
              onComplete={finishSourcesEditing}
            />
          </Unauthorized>
        </AuthorizationCheck>
      )}
      {sources && sources.length > 0 ? (
        <SimpleTable
          id={`source-table-${id}`}
          data-testid={`source-table-${id}`}
          dataDensity={"small"}
          className={"source-table"}
          background={"white"}
          hover={false}>
          <TableHead>
            <TableRow>
              <TableCell className={"source-type"}>
                {t("entity-detail.dealer.sources.type")}
              </TableCell>
              <TableCell className={"source-id"}>
                {t("entity-detail.dealer.sources.id")}
              </TableCell>
              <TableCell className={"source-actions"} />
            </TableRow>
          </TableHead>
          <TableBody>
            {sources.map((source: Source) => (
              <TableRow key={`${source.sourceType}|${source.sourceId}`}>
                <TableCell className={"source-type"}>
                  {source.sourceType}
                </TableCell>
                <TableCell className={"source-id"}>
                  {source.sourceType === "CACM" ? (
                    <span
                      onClick={() =>
                        navigateToEntity(
                          source.sourceId,
                          EntitySourceType.CLIENT_HIERARCHY
                        )
                      }>
                      {source.sourceId}
                    </span>
                  ) : (
                    source.sourceId
                  )}
                </TableCell>
                <TableCell className={"source-actions"}>
                  <ActionButtons>
                    <CopyButton
                      qualifier={source.sourceId}
                      item={t("entity-detail.dealer.sources.id")}
                      content={source.sourceId}
                      inline={true}
                    />
                  </ActionButtons>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </SimpleTable>
      ) : (
        <Typography tag={"span"} className={"no-sources"} variant={"body-sm"}>
          {t("entity-detail.dealer.sources.none")}
        </Typography>
      )}
    </TabRow>
  );
};
