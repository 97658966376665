import {FC, Key, useState} from "react";
import {
  ClientEntity,
  Oem,
  OperationalEntity,
  UpdateOemsInput
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {ErrorHandler, OnCompleteCallback} from "../../../utils";
import {useTranslation} from "react-i18next";
import {ModalWithAlert, useAlert} from "../../../alerting";
import {useOperationalEntity} from "../../context";
import {
  MutationFunctionOptions,
  OperationVariables,
  useLazyQuery,
  useMutation
} from "@apollo/client";
import {
  EntityResponse,
  LookupOperationalEntity,
  UpdateOperationalEntityOems
} from "../../backend";
import {Endpoints} from "../../../runtime";
import {assertEntityPresent} from "../../boids/transforms";
import {catchFalseSuccess} from "../../../backend";
import {Box} from "@interstate/components/Box";
import {Table} from "@interstate/components/Table";
import {TableColumns} from "@interstate/components/Table/Types/tableTypes";
import {
  groupOemTableRowsBySource,
  oemTableRowsFromNameplates,
  OemTableRow
} from "./oems";
import "./EditOemsModal.scss";

export type SelectedOemsChangedHandler = (
  keys: Key[],
  rows: OemTableRow[]
) => void;

export const EditOemsModal: FC<{
  assigned: Oem[];
  sources: ClientEntity[];
  onComplete: OnCompleteCallback;
}> = ({assigned, sources, onComplete}) => {
  const {t} = useTranslation();
  const {alert, clearAlert, createErrorAlert} = useAlert({
    errorTitleKey: "toast.backend-error"
  });
  const [working, setWorking] = useState(false);
  const [selectedTableRowsBySource, setSelectedTableRowsBySource] = useState(
    () => groupOemTableRowsBySource(assigned, sources)
  );
  const {entity, setEntity} = useOperationalEntity();

  const [lookUpOperationalEntity] = useLazyQuery<
    EntityResponse<OperationalEntity>
  >(LookupOperationalEntity, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });

  const [updateOems] = useMutation(UpdateOperationalEntityOems, {
    context: {endpoint: Endpoints.APPSYNC},
    fetchPolicy: "network-only"
  });

  const selectedRowKeysFor = (caId: string): Key[] =>
    selectedTableRowsBySource[caId].map(row => row.key);

  const selectedRowsChangedFor = (caId: string): SelectedOemsChangedHandler => {
    return (_keys: Key[], rows: OemTableRow[]) => {
      setSelectedTableRowsBySource(selectedTableRowsBySource => {
        selectedTableRowsBySource[caId] = rows;
        return selectedTableRowsBySource;
      });
    };
  };

  const collectSelectedOems = (): Oem[] => {
    return Object.values(selectedTableRowsBySource)
      .flat()
      .map(row => {
        const {key, ...oem} = row;
        return oem;
      });
  };

  const withSelected = (): MutationFunctionOptions<
    any,
    OperationVariables,
    {
      endpoint: Endpoints;
    }
  > => ({
    variables: {
      oemInput: {
        entityId: entity.id,
        oems: collectSelectedOems()
      } as UpdateOemsInput
    }
  });

  const clearErrors = (): void => {
    clearAlert();
  };

  const handleError: ErrorHandler = (error: any) => {
    createErrorAlert(error);
  };

  const refreshEntity = async (): Promise<void> => {
    return await lookUpOperationalEntity({variables: {id: entity.id}})
      .then(assertEntityPresent)
      .then(setEntity);
  };

  const saveChanges = async () => {
    setWorking(true);
    clearErrors();
    return await updateOems(withSelected())
      .then(catchFalseSuccess)
      .then(refreshEntity)
      // Signal that the operation is complete; changes were applied
      .then(() => onComplete(true))
      .catch(handleError)
      .finally(() => setWorking(false));
  };

  const cancelChanges = () => {
    // Signal that the operation is complete; changes were discarded
    onComplete(false);
  };

  const columns: TableColumns = [
    {
      className: "column-oem",
      dataIndex: "abbreviation",
      title: t("entity-detail.dealer.oems.oem")
    },
    {
      className: "column-id",
      dataIndex: "id",
      title: t("entity-detail.dealer.oems.id")
    },
    {
      className: "column-nameplate",
      dataIndex: "nameplateDescription",
      title: t("entity-detail.dealer.oems.nameplate")
    },
    {
      className: "column-location",
      dataIndex: "locationId",
      title: t("entity-detail.dealer.oems.location")
    }
  ];

  return (
    <ModalWithAlert
      id={"edit-oems-modal"}
      data-testid={"edit-oems-modal"}
      show={true}
      size={"large"}
      header={t("modal.header.edit-oems")}
      alert={alert}
      footer={{
        primary: {
          action: saveChanges,
          label: t("common-actions.save-changes"),
          isLoading: working
        },
        options: {
          action: cancelChanges,
          label: t("common-actions.cancel"),
          isLoading: working,
          buttonStyle: "tertiary"
        }
      }}
      onHide={cancelChanges}>
      <Box component={"p"} className={"edit-oems-modal-instructions"}>
        {t("entity-detail.dealer.oems.assignment", {
          entity: entity?.organizationInformation?.dbaName
        })}
      </Box>
      {sources.map(source => (
        <Box
          key={source.caId}
          component={"figure"}
          id={`edit-oems-table-container-${source.caId}`}
          data-testid={`edit-oems-table-container-${source.caId}`}
          className={"edit-oems-table-container"}>
          <Box component={"figcaption"} className={"edit-oems-table-caption"}>
            {t("entity-detail.dealer.oems.from-source", {
              source: source.custName
            })}
          </Box>
          <Table
            id={`edit-oem-table-${source.caId}`}
            data-testid={`edit-oem-table-${source.caId}`}
            className={"edit-oem-table"}
            borderType={"contained"}
            dataDensity={"small"}
            columns={columns}
            data={oemTableRowsFromNameplates(source.clientNameplates)}
            enableRowSelection
            selectedRowKeys={selectedRowKeysFor(source.caId)}
            onChangeSelectedRows={selectedRowsChangedFor(source.caId)}
            emptyText={t("entity-detail.dealer.oems.none")}
          />
        </Box>
      ))}
    </ModalWithAlert>
  );
};
