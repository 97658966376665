import {
  type Nameplate,
  nameplateToOem
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {OemTableRow, toOemTableRow, comparingOemTableRows} from "./OemTableRow";

/**
 * This function is used to construct the list of {@link OemTableRow} instances serving as
 * the backing table data for a list of OEMs available to be assigned.
 *
 * @param nameplates A list of {@link Nameplate} instances to be considered for OEM assignment.
 * @returns A list of {@link OemTableRow} instances representing the available OEMs.
 */
export const oemTableRowsFromNameplates = (
  nameplates: Nameplate[] = []
): OemTableRow[] =>
  // prettier-ignore
  nameplates
    ?.map(nameplateToOem)
    ?.map(toOemTableRow)
    ?.sort(comparingOemTableRows);
