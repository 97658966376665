import {useAnalytics} from "@common-core/react-analytics";
import {
  HomeIcon,
  MagnifyingGlassIcon,
  Squares2x2Icon
} from "@interstate/components/Icons";
import {SidebarItem} from "../layout";
import {useNavigation} from "./useNavigation";

export type Page =
  | "home"
  | "search"
  | "client-search"
  | "client-hierarchy-search";

export const useSidebarItems = (active?: Page): SidebarItem[] => {
  const {navigateToHome, navigateToSearch, navigateToClientSearch} =
    useNavigation();
  const {productEvent} = useAnalytics();

  return [
    {
      id: "home",
      active: active === "home",
      labelKey: "navigation.home",
      icon: HomeIcon,
      onClick: event => {
        productEvent({
          name: "navigation_item_clicked",
          properties: {
            location: "Sidebar",
            value: "Home",
            result: "Navigated to Home"
          }
        });
        navigateToHome(event);
      }
    },
    {
      id: "search",
      active: active === "search",
      labelKey: "navigation.search",
      icon: MagnifyingGlassIcon,
      onClick: event => {
        productEvent({
          name: "navigation_item_clicked",
          properties: {
            location: "Sidebar",
            value: "Operational Search",
            result: "Navigated to Operational Search"
          }
        });
        navigateToSearch(event);
      }
    },
    {
      id: "client-hierarchy-search",
      active: active === "client-hierarchy-search",
      labelKey: "navigation.create-orgs",
      icon: Squares2x2Icon,
      onClick: event => {
        productEvent({
          name: "navigation_item_clicked",
          properties: {
            location: "Sidebar",
            value: "Client Hierarchy Search",
            result: "Navigated to Client Hierarchy Search"
          }
        });
        navigateToClientSearch(event);
      }
    }
  ];
};
