import {
  type ClientEntity,
  nameplateToOem,
  Oem,
  oemEquals
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {OemTableRow, toOemTableRow} from "./OemTableRow";

export type OemTableRowsBySource = Record<string, OemTableRow[]>;
export type NameplateSource = Pick<ClientEntity, "caId" | "clientNameplates">;

/**
 * This function is used to construct the initial collection of selected {@link OemTableRow} instances,
 * serving as the backing table data for the tables of selected OEMs in the EditOemsModal component,
 * grouped by {@link ClientEntity.caId|Client Entity identifier}.
 *
 * The data structure enables the UI to present the list of OEMs available for selection per source.
 *
 * @param assigned A list of {@link Oem|oems} currently assigned to the operational entity being viewed.
 * @param sources A list of {@link NameplateSource} instances to which the operational entity being viewed is linked.
 */
export const groupOemTableRowsBySource = (
  assigned: Oem[] = [],
  sources: NameplateSource[] = []
): OemTableRowsBySource => {
  return sources.reduce((oemTableRows, source) => {
    oemTableRows[source.caId] =
      source.clientNameplates
        ?.map(nameplateToOem)
        ?.filter(sourceOem =>
          assigned.find(selectedOem => oemEquals(selectedOem, sourceOem))
        )
        ?.map(toOemTableRow) || [];
    return oemTableRows;
  }, {} as OemTableRowsBySource);
};
