import {FC, JSXElementConstructor, ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {
  SimpleTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@interstate/components/SimpleTable";
import {Popover} from "@interstate/components/Popover";
import {TabRow} from "../../tab-content";
import {EntityCard} from "../card";
import {HierarchyNode} from "./HierarchyNode";
import "./HierarchyNodePopover.scss";
import {oemUniqueKey} from "@common-core/coat-operational-hierarchy-appsync-model";

export interface EntityPopoverProps {
  node: HierarchyNode;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

export const HierarchyNodePopover: FC<EntityPopoverProps> = ({
  node,
  children
}) => {
  const {t} = useTranslation();

  return (
    <Popover
      id={"entity-parent-popover"}
      data-testid={"entity-parent-popover"}
      trigger={"hover"}
      position={"right"}
      popoverContent={
        <EntityCard
          id={"entity-card-parent"}
          className={"popover-entity-card"}
          heading={"h4"}
          entity={node}>
          <TabRow
            name={"oems"}
            header={<h4>{t("entity-detail.dealer.oems.details")}</h4>}>
            {node.oems && node.oems.length > 0 ? (
              <SimpleTable
                id={`oem-table-${node.id}`}
                data-testid={`oem-table-${node.id}`}
                className={"oem-table"}
                background={"white"}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("entity-detail.dealer.oems.oem")}</TableCell>
                    <TableCell>{t("entity-detail.dealer.oems.id")}</TableCell>
                    <TableCell>
                      {t("entity-detail.dealer.oems.nameplate")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {node.oems.map(oem => (
                    <TableRow key={oemUniqueKey(oem)}>
                      <TableCell
                        className={
                          "table-cell-oem table-cell-oem-abbreviation"
                        }>
                        {oem.abbreviation}
                      </TableCell>
                      <TableCell className={"table-cell-oem table-cell-oem-id"}>
                        {oem.id}
                      </TableCell>
                      <TableCell
                        className={"table-cell-oem table-cell-oem-nameplate"}>
                        {oem.nameplateDescription}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </SimpleTable>
            ) : (
              <span className={"no-oems"}>
                {t("entity-detail.dealer.oems.none")}
              </span>
            )}
          </TabRow>
        </EntityCard>
      }>
      {children}
    </Popover>
  );
};
