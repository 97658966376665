import {FC} from "react";
import {useTranslation} from "react-i18next";
import {
  Oem,
  OrganizationInformation
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {TabColumn} from "../../../tab-content";
import {DetailsColumnHeader} from "../../overview";
import {BusinessInformation} from "./BusinessInformation";
import {EditorAddress} from "./EditorAddress";
import {EditorOems} from "./EditorOem";

interface CreateDetailsColumnProps {
  id: string;
  organizationInformation: OrganizationInformation;
  oems?: Oem[];
}

export const CreateDetailsColumn: FC<CreateDetailsColumnProps> = ({
  id,
  organizationInformation,
  oems
}) => {
  const {t} = useTranslation();
  const {address, telecomNumbers} = organizationInformation;
  return (
    <TabColumn
      name={"details-column"}
      header={
        <DetailsColumnHeader
          label={t("create-entity.new-operational-details-label")}
          id={id}
        />
      }>
      <BusinessInformation
        id={id}
        organizationInformation={organizationInformation}
      />
      <EditorAddress
        id={id}
        streetAddressLine1={address?.streetAddressLine1}
        streetAddressLine2={address?.streetAddressLine2}
        city={address?.city}
        state={address?.state}
        country={address?.country}
        zip={address?.zip}
        telecomNumbers={telecomNumbers ? telecomNumbers : []}
      />
      <EditorOems id={id} oems={oems} />
    </TabColumn>
  );
};
