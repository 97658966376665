import {
  ClientAddress,
  ClientEntity,
  Nameplate
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {NamedSources} from "../operational-hierarchy/overview-tab";

export interface FullSource {
  sourceId: string;
  name: string;
  sourceType: string;
  status: string;
  phoneNumber: string;
  addresses: ClientAddress[];
  oems: Nameplate[];
}
export const toNamedSources = (clients: ClientEntity[]) => {
  return clients.map(client => {
    return {
      sourceId: client.caId,
      name: client.custName,
      sourceType: "CACM"
    } as NamedSources;
  });
};

export const toFullSource = (clients: ClientEntity[]) => {
  return clients.map(client => {
    return {
      sourceId: client.caId,
      name: client.custName,
      sourceType: "CACM",
      status: client.custStatus,
      phoneNumber: client.custPhoneNumber,
      addresses: client.addresses,
      oems: client.clientNameplates
    } as FullSource;
  });
};
