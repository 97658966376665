import {
  ClientEntity,
  Oem,
  Source
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {TabRow} from "../../../tab-content";
// eslint-disable-next-line import/namespace
import {OemTableBody} from "../../overview";
import "./OperationalOems.scss";
import {PermissionBasedEditButton} from "../../../action-buttons";
import {useFlags} from "launchdarkly-react-client-sdk";
import {features} from "../../../features";
import {EditOemsModal} from "./EditOemsModal";
import {useToastErrorHandler} from "../../../backend";
import {useLazyQuery} from "@apollo/client";
import {
  LookUpClientEntitiesByIds,
  LookupClientEntitiesResponse
} from "../../backend";
import {Endpoints} from "../../../runtime";
import {assertClientsPresent} from "../../boids/transforms";
import {OnCompleteCallback} from "../../../utils";

export interface OemsProps {
  id: string;
  oems: Oem[] | undefined;
}
export const OperationalOems: FC<{
  id: string;
  oems: Oem[] | undefined;
  sources: Source[];
}> = ({id, oems = [], sources}) => {
  const {t} = useTranslation();
  const flags = useFlags();
  const [linked, setLinked] = useState<ClientEntity[]>([]);
  const [editingOems, setEditingOems] = useState<boolean>(false);
  const toastError = useToastErrorHandler();

  const [lookUpClientEntities, {loading}] =
    useLazyQuery<LookupClientEntitiesResponse>(LookUpClientEntitiesByIds, {
      context: {
        endpoint: Endpoints.APPSYNC
      },
      fetchPolicy: "network-only"
    });

  const beginEditing = () => {
    if (sources && sources.length > 0) {
      // TODO: Source type is being completely ignored
      const ids = sources.map(source => source.sourceId);
      lookUpClientEntities({variables: {ids}})
        .then(assertClientsPresent)
        .then(setLinked)
        .then(() => {
          setEditingOems(true);
        })
        .catch(toastError);
    } else {
      setEditingOems(true);
    }
  };

  const finishOemsEditing: OnCompleteCallback = () => {
    setEditingOems(false);
  };
  return (
    <TabRow
      name={"operational-oems"}
      header={
        <h4>
          {t("entity-detail.dealer.oems.details")}
          {flags[features.dev.editOems] && (
            <PermissionBasedEditButton
              onClick={beginEditing}
              loading={loading}
              qualifier={"oems"}
              tooltip={t("entity-detail.dealer.oems.edit")}
              inline={false}
              permissions={[
                {
                  resource: "operational-entity",
                  action: "edit"
                }
              ]}
            />
          )}
        </h4>
      }>
      {editingOems && (
        <EditOemsModal
          sources={linked}
          assigned={oems}
          onComplete={finishOemsEditing}
        />
      )}
      <OemTableBody id={id} oems={oems} />
    </TabRow>
  );
};
