/* eslint-disable no-console */
import {createBrowserHistory} from "history";
import {FC} from "react";
import {InterstateThemeProvider} from "@interstate/components/InterstateThemeProvider";
import {interstateThemeRegistry} from "@interstate/components/InterstateTheming";
import CxThemeProvider from "@cx/ui/CxThemeProvider";
import {Router} from "react-router-dom";
import {newRuntimeController} from "@common-core/runtime-js/runtime";
import {installRuntimeEnvironment} from "@common-core/runtime-js/support";
import {RuntimeProvider} from "@common-core/react-runtime/context";
import {RuntimeControl} from "@common-core/react-runtime/control";
import {
  environments,
  runtimes,
  EnvironmentTable,
  filter,
  grouper,
  useInspector,
  MetadataTable
} from "./runtime";
import {FeatureProvider, FeatureGuard} from "./features";
import {SecurityProvider} from "./security";
import {AnalyticsFacade} from "./analytics";

import {GlobalHeader} from "./GlobalHeader";
import {Routes} from "./Routes";
import "./Application.scss";
import {ActivityIndicatorProvider} from "./activity-indicator";
import {GraphqlClientProvider} from "./backend";
import {FeedbackProvider} from "./feedback";
import {OperationalSearchStateProvider} from "./entities/operational-hierarchy/search/context";
import {ClientHierarchySearchStateProvider} from "./entities/client-hierarchy/search/context";
import {PendoOptionsProvider} from "./pendo";
import {UserAuthorizationProvider} from "./access/context/UserAuthorizationProvider";

const history = createBrowserHistory();
// prettier-ignore
const controller = newRuntimeController(runtimes, environments)
  .onActivation(event => {
    installRuntimeEnvironment(event);
    console.group("Runtime Environment");
    console.dir(event);
    console.groupEnd();
  });
/**
 * The {@link Application} component is the main entrypoint and
 * establishes the cross-cutting concerns relevant to the core
 * application, these include things like:
 * <ul>
 *   <li>CX Theme</li>
 *   <li>LoadingIndicator</li>
 *   <li>Routing</li>
 *   <li>Runtime Environment</li>
 *   <li>Launch Darkly</li>
 *   <li>Security</li>
 *   <li>Analytics</li>
 * </ul>
 *
 * Applying SOC here enables application components to be rendered with
 * mocks for all the contexts listed above so that components may be
 * tested within and have access to all the same contexts and hooks as
 * they would at runtime.
 *
 * @see CxThemeProvider
 * @see LoadingIndicatorProvider
 * @see RuntimeProvider
 * @see RuntimeControl
 * @see SecurityProvider
 * @see GlobalHeader
 * @see Router
 * @see Routes
 * @constructor
 */
export const Application: FC = () => {
  return (
    <CxThemeProvider>
      <InterstateThemeProvider
        themeName={"Interstate"}
        applicationName={"commons-operations-admin-tool"}
        scopeName={"commons-operations-admin-tool"}
        themeRegistries={[interstateThemeRegistry]}>
        <ActivityIndicatorProvider>
          <RuntimeProvider activator={controller}>
            <FeatureProvider>
              <FeatureGuard>
                <SecurityProvider history={history}>
                  <PendoOptionsProvider />
                  <FeedbackProvider>
                    <GraphqlClientProvider>
                      <UserAuthorizationProvider>
                        <AnalyticsFacade>
                          <RuntimeControl
                            panelWidth={"50%"}
                            filter={filter}
                            useInspector={useInspector}
                            grouper={grouper}
                            metadataUi={MetadataTable}
                            environmentUi={EnvironmentTable}
                            displayOn={event =>
                              event.ctrlKey &&
                              event.altKey &&
                              event.code === "KeyS"
                            }
                          />
                          <GlobalHeader />
                          <OperationalSearchStateProvider>
                            <ClientHierarchySearchStateProvider>
                              <Router history={history}>
                                <Routes />
                              </Router>
                            </ClientHierarchySearchStateProvider>
                          </OperationalSearchStateProvider>
                        </AnalyticsFacade>
                      </UserAuthorizationProvider>
                    </GraphqlClientProvider>
                  </FeedbackProvider>
                </SecurityProvider>
              </FeatureGuard>
            </FeatureProvider>
          </RuntimeProvider>
        </ActivityIndicatorProvider>
      </InterstateThemeProvider>
    </CxThemeProvider>
  );
};
