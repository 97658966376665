import {UserAuthorization} from "@common-core/coat-operational-hierarchy-appsync-model";

export interface UserAuthorizationsMap {
  [key: string]: boolean;
}

export const transformUserAuthorizations = (
  userAuthorizations: UserAuthorization[]
): UserAuthorizationsMap => {
  return userAuthorizations.reduce((map, userAuthorization) => {
    const key = `${userAuthorization.resourceIdentifier}.${userAuthorization.resourceType}.${userAuthorization.action}`;
    map[key] = userAuthorization.allowed;
    return map;
  }, {} as UserAuthorizationsMap);
};
