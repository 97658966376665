import {FetchResult} from "@apollo/client";
import {UpdateResponse} from "@common-core/coat-operational-hierarchy-appsync-model";

export const catchFalseSuccess = async (
  response: FetchResult<UpdateResponse>
): Promise<void> => {
  if (response.data?.success === false) {
    throw new Error(
      "There was an unknown error calling the backend. Please try again, or if the problem persists, contact your administrator."
    );
  }
};
