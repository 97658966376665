import {FC} from "react";
import {
  SimpleTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@interstate/components/SimpleTable";
import {useTranslation} from "react-i18next";
import {Typography} from "@interstate/components/Typography";
import {OemsProps} from "../operational-hierarchy/overview-tab/OperationalOems";
import {
  comparingOemTableRows,
  toOemTableRow
} from "../operational-hierarchy/overview-tab/oems";

export const OemTableBody: FC<OemsProps> = ({id, oems}) => {
  const {t} = useTranslation();
  return oems && oems.length > 0 ? (
    <SimpleTable
      id={`oem-table-${id}`}
      data-testid={`oem-table-${id}`}
      dataDensity={"small"}
      className={"oem-table"}
      background={"white"}>
      <TableHead>
        <TableRow>
          <TableCell>{t("entity-detail.dealer.oems.oem")}</TableCell>
          <TableCell>{t("entity-detail.dealer.oems.id")}</TableCell>
          <TableCell>{t("entity-detail.dealer.oems.location")}</TableCell>
          <TableCell>{t("entity-detail.dealer.oems.nameplate")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {oems
          .map(toOemTableRow)
          .sort(comparingOemTableRows)
          .map(row => (
            <TableRow key={row.key} className={"oem-table-row"}>
              <TableCell
                className={"table-cell-oem table-cell-oem-abbreviation"}>
                {row.abbreviation}
              </TableCell>
              <TableCell className={"table-cell-oem table-cell-oem-id"}>
                {row.id}
              </TableCell>
              <TableCell className={"table-cell-oem table-cell-oem-nameplate"}>
                {row.nameplateDescription}
              </TableCell>
              <TableCell className={"table-cell-oem table-cell-oem-location"}>
                {row.locationId}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </SimpleTable>
  ) : (
    <Typography tag={"span"} className={"no-oems"} variant={"body-sm"}>
      {t("entity-detail.dealer.oems.none")}
    </Typography>
  );
};
