/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useCallback, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {
  useRecentlyViewedOperationalEntities,
  useSidebarItems
} from "../../hooks";
import {ErrorPage, PageTemplate} from "../../layout";
import {ActivityIndicator} from "../../activity-indicator";
import {OperationalEntityProvider} from "../context";
import "../EntityPage.scss";
import "../EntityDetail.scss";
import {BackendError} from "@common-core/runtime-js/backend";
import {useQuery} from "@apollo/client";
import {EntityResponse, LookupOperationalEntity} from "../backend";
import {Endpoints, Environment} from "../../runtime";
import {useFlags} from "launchdarkly-react-client-sdk";
import {BackToButton, Pages} from "../../back-buttons";
import {useTranslation} from "react-i18next";
import {EmbeddedComponentTab} from "../embedded";
import {useEnvironment} from "@common-core/react-runtime/context";
import {useAnalytics} from "@common-core/react-analytics";
import {HashTabs} from "../../hash-tabs";
import {OperationalOverviewTab} from "./overview-tab";
import {OperationalHierarchyTab} from "./hierarchy-tab";
import {OperationalEntity} from "@common-core/coat-operational-hierarchy-appsync-model";
import {PermissionBasedEditButton} from "../../action-buttons";
import {EditDbaNameModal} from "./overview-tab/EditDbaNameModal";
import {OnCompleteCallback} from "../../utils";
import {EDIT_DBA_NAME} from "../../access";

interface RouteParams {
  id: string;
}

/**
 * The Page concerns itself with routing information and extracts
 * what it needs to prepare the other components
 * for display
 *
 * @constructor
 */
export const OperationalEntityPage: FC = () => {
  const {id} = useParams<RouteParams>();
  const sidebarItems = useSidebarItems();
  interface LocationState {
    from: string;
    query: string;
  }
  const environment = useEnvironment<Environment>();
  const {t} = useTranslation();
  const {productEvent} = useAnalytics();
  const {addBrowsedOperationalEntity} = useRecentlyViewedOperationalEntities();
  const location = useLocation<LocationState>();
  const [editingDbaName, setDbaNameEditing] = useState<boolean>(false);
  const finishDbaNameEditing: OnCompleteCallback = () => {
    setDbaNameEditing(false);
  };
  const backToPage =
    location.state?.from === "/search" ? Pages.Search : Pages.Home;
  const flags = useFlags();
  const {loading, error, data} = useQuery<EntityResponse<OperationalEntity>>(
    LookupOperationalEntity,
    {
      variables: {id},
      context: {
        endpoint: Endpoints.APPSYNC
      }
    }
  );
  // // TODO: Add test around this - test on hierarchy?
  const translate = useCallback(
    (tab: string) => {
      return tab === "hierarchy" || tab === "overview"
        ? t(`entity-detail.${tab}`, {lng: "en"})
        : t(`entity-tabs.embedded-components.${tab}`, {lng: "en"});
    },
    [t]
  );
  const entity = data?.entity || ({} as OperationalEntity);
  useEffect(() => {
    if (entity.id) {
      addBrowsedOperationalEntity(entity.id);
    }
  }, [entity?.id]);

  if (error) {
    return <ErrorPage error={error} logo={false} sidebarItems={sidebarItems} />;
  }

  if (loading) {
    return (
      <PageTemplate
        id={"operational-entity-page"}
        title={"Operational Entity Page"}
        sidebarItems={sidebarItems}>
        <ActivityIndicator />
      </PageTemplate>
    );
  }

  if (!data?.entity) {
    return (
      <ErrorPage
        error={new BackendError("Entity not found", 404)}
        logo={false}
        sidebarItems={sidebarItems}
      />
    );
  }

  return (
    <PageTemplate
      id={"operational-entity-page"}
      title={"Operational Entity Page"}
      breadcrumbs={<BackToButton page={backToPage} />}
      sidebarItems={sidebarItems}>
      <OperationalEntityProvider entity={data.entity}>
        <div className={"entity-detail"} data-testid={"entity-detail"}>
          {editingDbaName && (
            <EditDbaNameModal
              id={data.entity.id}
              onComplete={finishDbaNameEditing}
              currentDbaName={data.entity.organizationInformation.dbaName}
            />
          )}
          {/* Could this header be another component?*/}
          <header className={"entity-detail-header"}>
            <h2 className={"entity-business-name"}>
              {data.entity.organizationInformation?.dbaName}
            </h2>
            <PermissionBasedEditButton
              onClick={() => setDbaNameEditing(true)}
              qualifier={"dba-name"}
              tooltip={t("entity-detail.edit-dba-name")}
              inline={true}
              permissions={[EDIT_DBA_NAME]}
            />
          </header>
          <HashTabs
            id={"entity-detail-tabs"}
            initial={"overview"}
            onTabActivated={event => {
              productEvent({
                name: `entity_detail_tab_clicked`,
                properties: {
                  location: "Dealer Details Overview",
                  value: `${translate(event.tab)}`,
                  result: "Tab Displayed"
                }
              });
            }}>
            <OperationalOverviewTab
              hash={"overview"}
              label={t("entity-tabs.overview").toString()}
            />
            <OperationalHierarchyTab
              hash={"hierarchy"}
              label={t("entity-tabs.hierarchy")}
            />
            {environment.tabComponents
              .filter(component => {
                return component.featureFlag
                  ? flags[component.featureFlag]
                  : true;
              })
              .map(component => (
                <EmbeddedComponentTab
                  key={component.element}
                  hash={component.hash || component.element}
                  label={t(
                    `entity-tabs.embedded-components.${component.element}`
                  )}
                  definition={component}
                  entity={data.entity.id}
                />
              ))}
          </HashTabs>
        </div>
      </OperationalEntityProvider>
    </PageTemplate>
  );
};
