import {ErrorHandler, OnCompleteCallback} from "../../../utils";
import {FC, useState} from "react";
import {
  ClientAddress,
  OperationalEntity,
  OrganizationAddress,
  UpdateAddressInput
} from "@common-core/coat-operational-hierarchy-appsync-model";
import {ModalWithAlert, useAlert} from "../../../alerting";
import {useTranslation} from "react-i18next";
import {useOperationalEntity} from "../../context";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  EntityResponse,
  LookupOperationalEntity,
  UpdateOperationalEntityAddress
} from "../../backend";
import {Endpoints} from "../../../runtime";
import {assertEntityPresent} from "../../boids/transforms";
import {RadioButtonList} from "@interstate/components/RadioButtonList";
import {FullSource} from "../../util/toNamedSources";
import "./EditAddressModal.scss";
import {InterstateOnChangeEvent} from "@interstate/components/InterstateEvents";
import {RadioButtonEventValue} from "@interstate/components/RadioButtonList/Types/radioButtonListTypes";
import {catchFalseSuccess} from "../../../backend";

export const EditAddressModal: FC<{
  id: string;
  currentAddress: OrganizationAddress;
  sources: FullSource[];
  onComplete: OnCompleteCallback;
}> = ({id, currentAddress, onComplete, sources}) => {
  const {t} = useTranslation();
  const {alert, clearAlert, createErrorAlert} = useAlert({
    errorTitleKey: "toast.backend-error"
  });
  const [working, setWorking] = useState(false);
  const [value, setValue] = useState<OrganizationAddress>(currentAddress);
  const {entity, setEntity} = useOperationalEntity();

  const [lookUpOperationalEntity] = useLazyQuery<
    EntityResponse<OperationalEntity>
  >(LookupOperationalEntity, {
    context: {
      endpoint: Endpoints.APPSYNC
    },
    fetchPolicy: "network-only"
  });

  const [updateAddress] = useMutation(UpdateOperationalEntityAddress, {
    context: {endpoint: Endpoints.APPSYNC}
  });

  const clearErrors = (): void => {
    clearAlert();
  };

  const handleError: ErrorHandler = (error: any) => {
    createErrorAlert(error);
  };

  const refreshEntity = async (): Promise<void> => {
    return await lookUpOperationalEntity({variables: {id: entity.id}})
      .then(assertEntityPresent)
      .then(setEntity);
  };

  const saveChanges = async () => {
    setWorking(true);
    clearErrors();
    const addressInput: UpdateAddressInput = {
      address: value,
      entityId: id
    };
    await updateAddress({
      variables: {addressInput}
    })
      .then(catchFalseSuccess)
      .then(refreshEntity)
      // If the update call returns an error, give the user the opportunity to try again
      .then(() => onComplete(true))
      .catch(handleError)
      .finally(() => setWorking(false));
  };

  const cancelChanges = () => {
    onComplete(false);
  };

  // Current Interstate Radio component requires string labels
  const clientAddressToString = (name: string, address: ClientAddress) => {
    let result = `${name}\n`;
    result += `${address.addrLine1}\n`;
    if (address.addrLine2) result += `${address.addrLine2}\n`;
    result += `${address.city}, ${address.provinceCd} ${address.postalCd}\n${address.countryCd}`;
    return result;
  };

  // Convert client address selection to operational address type to send to api
  const clientToOperational = (
    clientAddress: ClientAddress
  ): OrganizationAddress => {
    return {
      streetAddressLine1: clientAddress.addrLine1,
      streetAddressLine2: clientAddress.addrLine2,
      city: clientAddress.city,
      state: clientAddress.provinceCd,
      zip: clientAddress.postalCd,
      country: clientAddress.countryCd
    };
  };

  // For each source grab all the addresses, and put them into an object with the name of the source, and an increasing value
  // to be used as the radio components value
  // This will allow for program to do a lookup in the array without having to do more string/object translations
  let val = 0;
  const mappedAddresses = sources.flatMap(source => {
    return source.addresses.map(address => {
      return {value: (val++).toString(), name: source.name, address};
    });
  });

  // Current address of the operational entity, to be used as the preselected choice in the radio component.
  const currClientAddress = mappedAddresses.filter(
    option =>
      option.address.addrLine1 === currentAddress?.streetAddressLine1 &&
      option.address.city === currentAddress.city &&
      option.address.addrLine2 === currentAddress.streetAddressLine2 &&
      option.address.postalCd === currentAddress.zip &&
      option.address.provinceCd === currentAddress.state &&
      option.address.countryCd === currentAddress.country
  )[0];

  const setSelectedValue = (
    event: InterstateOnChangeEvent<RadioButtonEventValue>
  ) => {
    setValue(
      clientToOperational(
        mappedAddresses.filter(address => {
          return event.target.value === address.value;
        })[0].address
      )
    );
  };

  return (
    <ModalWithAlert
      id={"edit-address-modal"}
      data-testid={"edit-address-modal"}
      show={true}
      size={"large"}
      header={t("modal.header.edit-address")}
      alert={alert}
      footer={{
        primary: {
          action: saveChanges,
          label: t("common-actions.save-changes"),
          isLoading: working
        },
        options: {
          action: cancelChanges,
          label: t("common-actions.cancel"),
          isLoading: working,
          buttonStyle: "tertiary"
        }
      }}>
      <RadioButtonList
        id={"address-radio-list"}
        data-testid={"address-radio-list"}
        label={t("modal.text-input.edit-address")}
        name={"edit-address-list"}
        value={currClientAddress?.value}
        options={mappedAddresses.map(option => {
          return {
            value: option.value,
            label: clientAddressToString(option.name, option.address)
          };
        })}
        onChange={setSelectedValue}
      />
    </ModalWithAlert>
  );
};
